<template>
  <div class="container px-0 h-100">
    <div class="wide-box-full">
      <div class="card border-radius-1 mb-4 pb-4">
        <div
          class="card-header-profile border-bottom d-flex align-items-end pb-4"
        >
          <div class="card-title-profile flex-grow-1">
            <div class="d-flex flex-row">
              <div class="px-2">
                <img src="/media/brand/profile-avatar.png" alt="" />
              </div>
              <div class="p-4 profile-fullname align-middle">
                Izabela<br />
                Słowikowska
              </div>
              <div class="px-4 ">
                <div class="bordered-block-profile">
                  <h3 class="text-secondary p-1">113 zł</h3>
                  <span class="sub-title p-1">Prowizja 09/21</span>
                </div>
              </div>
              <div class="px-4">
                <div class="bordered-block-profile">
                  <h3 class="text-secondary p-1">10 polis</h3>
                  <span class="sub-title p-1">Wznowienia 09/21</span>
                </div>
              </div>
              <div class="px-4 ">
                <div class="bordered-block-profile">
                  <h3 class="text-secondary p-1">30 polis</h3>
                  <span class="sub-title p-1">Sprzedaż 08/21</span>
                </div>
              </div>
              <div class="px-4 ">
                <div class="bordered-block-profile">
                  <h3 class="text-secondary p-1">525 zł</h3>
                  <span class="sub-title p-1">Prowizja 08/21</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="d-flex justify-content-center flex-row nav-tabs profile-nav-tabs">
            <div class="profile-nav-link nav-link">
              <router-link :to="{ name: 'profile-account' }">
                Konto
              </router-link>
            </div>
            <div class="profile-nav-link nav-link">
              <router-link :to="{ name: 'profile-policies' }">
                Polisy
              </router-link>
            </div>
            <div class="profile-nav-link nav-link active">
              <router-link :to="{ name: 'profile-renewals' }">
                Wznowienia
              </router-link>
            </div>
            <div class="profile-nav-link nav-link">
              <router-link :to="{ name: 'profile-issues' }">
                Sprawy
              </router-link>
            </div>
            <div class="profile-nav-link nav-link">
              <router-link :to="{ name: 'profile-leads' }">
                Leady
              </router-link>
            </div>
            <div class="profile-nav-link nav-link">
              <router-link :to="{ name: 'profile-settlements' }">
                Rozliczenia
              </router-link>
            </div>
            <div class="profile-nav-link nav-link">
              <router-link :to="{ name: 'profile-calendar' }">
                Kalendarz
              </router-link>
            </div>
            <div class="profile-nav-link nav-link">
              <router-link :to="{ name: 'profile-trainings' }">
                Szkolenia
              </router-link>
            </div>
            <div class="profile-nav-link nav-link">
              <router-link :to="{ name: 'profile-permissions' }">
                Uprawnienia
              </router-link>
            </div>
            <div class="profile-nav-link nav-link">
              <router-link :to="{ name: 'profile-rodoidd' }">
                RODO/IDD
              </router-link>
            </div>
          </div>

          <div class="d-flex flex-row-reverse ">
            <div class="p-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                <g id="Group_1973" data-name="Group 1973" transform="translate(-170 -370)">
                  <path id="Path_1432" data-name="Path 1432" d="M9.843,19.687A2.343,2.343,0,1,0,7.5,17.343a2.343,2.343,0,0,0,2.343,2.344Z" transform="translate(168.358 366.716)" fill="#1d4f91"/>
                  <path id="Path_1433" data-name="Path 1433" d="M19.687,17.343A2.343,2.343,0,1,1,17.343,15a2.343,2.343,0,0,1,2.344,2.343Z" transform="translate(166.717 366.716)" fill="#1d4f91"/>
                  <path id="Path_1434" data-name="Path 1434" d="M24.843,19.687A2.343,2.343,0,1,0,22.5,17.343a2.343,2.343,0,0,0,2.343,2.344Z" transform="translate(165.074 366.716)" fill="#1d4f91"/>
                  <g id="Ellipse_2" data-name="Ellipse 2" transform="translate(170 370)" fill="none" stroke="#1d4f91" stroke-width="1.5">
                    <circle cx="14" cy="14" r="14" stroke="none"/>
                    <circle cx="14" cy="14" r="13.25" fill="none"/>
                  </g>
                </g>
              </svg>
            </div>
          </div>

          <b-table
            :items="policiesList.items"
            :fields="policiesList.fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            thead-class="profile-tblheader"
            responsive="sm"
            show-empty
            class=""
          >
            <template #cell(status)="row">
<!--              <b-badge pill variant="bg-warning">{{ row.value }}</b-badge>-->
              <span class="badge rounded-pill bg-warning text-dark">{{ row.value }}</span>
            </template>
          </b-table>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

export default {
  name: "ProfileRenewalsPageView",
  data() {
    return {
      selected: [],
      sortBy: "data",
      sortDesc: false,
      policiesList: {
        fields: [
          {
            key: "klient",
            label: "Klient",
            sortable: true
          },
          {
            key: "dni",
            label: "Dni",
            sortable: true
          },
          {
            key: "koniec",
            label: "Koniec",
            sortable: true
          },
          {
            key: "tu",
            label: "TU",
            sortable: true
          },
          {
            key: "produkt",
            label: "Produkt",
            sortable: true
          },
          {
            key: "polisa",
            label: "Polisa",
            sortable: false
          },
          {
            key: "przypis",
            label: "Przypis",
            sortable: true
          },
          {
            key: "status",
            label: "Status",
            sortable: false
          }
        ],
        items: [
          {
            klient: "Kazimierz Juszczykiew",
            dni: -5,
            koniec: "03-08-2022",
            tu: "Ergo Hestia",
            produkt: "Majątek Jupiter",
            polisa: "0987584930",
            przypis: "10.567 zł",
            status: "Klient zrezygnował"
          },
          {
            klient: "Anna Janczar",
            dni: -2,
            koniec: "10-08-2022",
            tu: "PZU",
            produkt: "Majątek Jupitor",
            polisa: "M29865980600",
            przypis: "1.465 zł",
            status: "P"
          },
          {
            klient: "PHU-EXBUD",
            dni: -1,
            koniec: "19-08-2022",
            tu: "InterRisk",
            produkt: "NNW - indyw.",
            polisa: "679r03",
            przypis: "345 zł",
            status: "W kontakcie"
          },
          {
            klient: "Kazimierz Juszczykiew...",
            dni: 0,
            koniec: "03-08-2022",
            tu: "Ergo Hestia",
            produkt: "AUTO - pakiet",
            polisa: "0987584930",
            przypis: "10.567 zł",
            status: "Wznowiona"
          },
          {
            klient: "Anna Janczar",
            dni: 3,
            koniec: "10-08-2022",
            tu: "PZU",
            produkt: "Majątek Jupiter",
            polisa: "M29865980600",
            przypis: "1.465 zł",
            status: "Oczekuje"
          },
          {
            klient: "PHU EXBUD",
            dni: 5,
            koniec: "19-08-2022",
            tu: "InterRisk",
            produkt: "NNW - indyw.",
            polisa: "6479r03",
            przypis: "345 zł",
            status: "Oczekuje"
          },
          {
            klient: "PHU EXBUD",
            dni: 10,
            koniec: "19-08-2022",
            tu: "InterRisk",
            produkt: "AUTO - OC",
            polisa: "6479r03",
            przypis: "345 zł",
            status: "Brak kontaktu"
          },
          {
            klient: "Kazimierz Juszczykiew...",
            dni: 20,
            koniec: "03-08-2022",
            tu: "Ergo Hestia",
            produkt: "AUTO - pakiet",
            polisa: "0987584930",
            przypis: "10.567 zł",
            status: "Wznowiona poza"
          },
          {
            klient: "Anna Janczar",
            dni: 25,
            koniec: "10-08-2022",
            tu: "PZU",
            produkt: "Mieszkanie",
            polisa: "m29865980600",
            przypis: "1.465 zł",
            status: "Oczekuje"
          },
          {
            klient: "PHU EXBUD",
            dni: 26,
            koniec: "19-08-2022",
            tu: "InterRisk",
            produkt: "Dom - H7",
            polisa: "6479r03",
            przypis: "345 zł",
            status: "Oczekuje"
          },
          {
            klient: "Anna Janczar",
            dni: 30,
            koniec: "10-08-2022",
            tu: "PZU",
            produkt: "Mieszkanie",
            polisa: "M29865980600",
            przypis: "1.465 zł",
            status: "Sprzedaż"
          },
          {
            klient: "PHU EXBUD",
            dni: 40,
            koniec: "19-08-2022",
            tu: "InterRisk",
            produkt: "Dom - H7",
            polisa: "6479r03",
            przypis: "345 zł",
            status: "Oczekuje"
          }

        ]
      }
    };
  },
  computed: {
  },
  mounted() {
  },
  methods: {
  }
};
</script>
